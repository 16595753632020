import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";

export const frontmatter = {
  title: "Día 20",
  week: "Semana 3",
  month: "abr",
  day: "02",
  monthNumber: "04",
  date: "2020-04-02",
  path: "/cronologia/semana-03#dia-02-abr",
};

const Day20 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModText>
          El Ministerio de Justicia ha establecido
          <strong>
            la transferencia bancaria como medio general para la realización de
            pagos en procesos judiciales
          </strong>
          durante la vigencia del estado de alarma, con el fin de evitar el
          riesgo de contagio de letrados y profesionales.
        </ModText>
        <ModTwoCols
          src="/images/svg/02_abr_agricultura.svg"
          alt="nuevo paquete de medidas para mitigar los efectos del
          impacto del COVID-19 en la agricultura, ganadería y pesca"
          small={false}
        >
          La Comisión Europea lanza un{" "}
          <strong>
            nuevo paquete de medidas para mitigar los efectos del impacto del
            COVID-19 en la agricultura, ganadería y pesca
          </strong>
          . En cuanto al sector pesquero, se adoptarán formalmente a partir del
          18 de abril y tendrán efecto retroactivo desde el 1 de febrero.
        </ModTwoCols>
        <ModImage
          src="/images/svg/02_abr_ganaderia.svg"
          alt="medidas para mitigar los efectos del impacto del COVID-19 en la agricultura, ganadería y pesca"
        />
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-02"} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, hasta el momento se han registrado 110.238
          casos (8.102 más que el día anterior), 10.003 fallecidos (9,1% de los
          casos positivos) y 26.743 curados (24,3% de los casos positivos). El
          total de personas en UCI asciende a 6.092.
        </ModText>
        <ModTwoCols
          src="/images/svg/02_abr_hospital.svg"
          alt="técnicas de diagnóstico de COVID19"
          small={true}
        >
          La Comisión Europea lanza un{" "}
          <strong>
            nuevo paquete de medidas para mitigar los efectos del impacto del
            COVID-19 en la agricultura, ganadería y pesca
          </strong>
          . En cuanto al sector pesquero, se adoptarán formalmente a partir del
          18 de abril y tendrán efecto retroactivo desde el 1 de febrero.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/02_abr_mascarilla.svg"
          alt="se han distribuido más de un millón de mascarillas"
          small={true}
        >
          El Gobierno de España, a través del Ministerio de Sanidad, continúa
          con su esfuerzo diario para dotar a las Comunidades Autónomas del
          material necesario para enfrentar la crisis del COVID-19. En las{" "}
          <strong>
            últimas 24 horas se han distribuido más de un millón de mascarillas
          </strong>
          entre las Comunidades Autónomas, y
          <strong>15.000 unidades de batas desechables</strong>.
        </ModTwoCols>
        <ModText>
          El <strong>Ministerio de Industria, Comercio y Turismo</strong>, por
          su parte, ha elaborado una serie de guías técnicas para empresas
          interesadas en la fabricación de equipos de protección individual y
          mascarillas quirúrgicas, que incluyen las especificaciones que han de
          cumplir y el proceso de verificación posterior.
        </ModText>
        <ModText>
          El <strong>Ministerio de Ciencia e Innovación</strong> coordina los
          esfuerzos institucionales en investigación e innovación frente a
          COVID-19 y está en contacto con las autoridades internacionales,
          dentro y fuera de la Unión Europea, para el seguimiento de los
          esfuerzos por buscar soluciones a esta pandemia.
        </ModText>
        <ModAnimation svg="/images/anim/02_abr_investigacion.svg" />
        <ModText>
          Las <strong>investigaciones científicas en España</strong> están
          siguiendo varias líneas de búsqueda para lograr tratamientos
          antivirales y una vacuna. Entre ellas, está la que sigue el Centro
          Nacional de Biotecnologia (CNB), del Consejo Superior de
          Investigaciones Científicas (CSIC), que va a permitir tener ya un{" "}
          <strong>primer candidato de vacuna a finales de abril</strong>.
        </ModText>
        <ModTwoCols
          src="/images/svg/02_abr_liquidez_agr_gana.svg"
          alt="aumentar la liquidez del sector agrícola y ganadero"
          small={true}
        >
          Para{" "}
          <strong>aumentar la liquidez del sector agrícola y ganadero</strong>,
          la Comisión aumentará los anticipos de pagos directos al 70%, así como
          los pagos de desarrollo rural al 85%. También se ampliará el plazo de
          presentación de la Política Agrícola Común hasta el 15 de junio y se
          rebajarán los requisitos y controles para evitar la pérdida de fondos.
        </ModTwoCols>
        <ModText>
          La <strong>OTAN</strong> se ha reunido hoy por videoconferencia para
          tratar de hacer frente a la pandemia del COVID-19 de forma unida y
          coordinada. Es la primera vez que se celebra una reunión ministerial
          de estas características en sus 70 años de historia.
        </ModText>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.mitma.es/transporte-terrestre/punto-de-informacion-de-servicios-de-restauracion"
            name="Punto de información de servicios de restauración"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day20;
